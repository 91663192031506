.footer__section {
  font-family: "Nunito Sans", sans-serif;
}
.footer__section .footer__top {
  padding: 100px;
  background: radial-gradient(
    108.01% 162.01% at 49.73% 106.8%,
    #3b0864 0%,
    #200942 39%,
    #0b0b28 77%,
    #030b1e 100%
  );
  border-radius: 0px;
  text-align: center;
}
.footer__section .footer__top h6 {
  color: #666dff;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  font-family: "Nunito Sans", sans-serif !important;
}
.footer__section .footer__top h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #f9f9f9;
  font-family: "Nunito Sans", sans-serif !important;
}
.footer__section .footer__top p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #f5f5ff;
  width: 657px;
  height: 52px;
  margin: 20px auto;
}
.footer__section .footer__links {
  width: 865px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto 0;
}
.footer__section .footer__links a {
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #f5f5ff;
}

.footer__section .footer__bottom {
  padding: 50px 0;
  background: #121620;
  text-align: center;
}
.footer__section .footer__bottom p {
  width: 726px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: rgba(249, 249, 255, 0.35);
  margin: 20px auto;
}
.footer__section .footer__bottom span {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: rgba(249, 249, 255, 0.65);
}

.community__links {
    width: 465px;
    margin: 40px auto;
}
.community__links .step__one {
    margin-bottom: 20px;
}
.community__links div img {
    margin-right: 15px;
    cursor: pointer;
    transition: transform 0.2s;
}
.community__links div img:hover {
    transform: scale(1.2);
}

@media (max-width: 912px) {
  .footer__section .footer__top {
    padding: 100px 20px;
  }
  .footer__section .footer__top p {
    width: 100%;
    margin-bottom: 100px;
  }
  .community__links, .footer__section .footer__links {
    width: 100%;
  }
  .footer__section .footer__links {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .footer__section .footer__links a {
    font-size: 14px;

  }
  .footer__section .footer__bottom p {
    width: 100%;
  }
  .footer__section .footer__bottom {
    padding: 50px 20px;
  }
}