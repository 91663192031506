.faqs__section {
    padding: 100px;
}
.faqs__section .section__head {
    background-image: url("../../../../public/images/FAQ.svg");
    padding-top: 40px;
}
.faqs__section .faqs {
    padding-bottom: 100px;
}
.faqs__section .faqs .faq {
    width: 1056px;
    min-height: 104px;
    margin: 0 auto 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.faqs__section .faqs .faq .faq__head, .faqs__section .faqs .faq .faq__head div {
    display: flex;
    align-items: center;
    font-weight: 700;
font-size: 25px;
line-height: 143.6%;
}
.faqs__section .faqs .faq .faq__head {
    justify-content: space-between;
    cursor: pointer;
}
.faqs__section .faqs .faq .faq__head .icon {
    color: #FFFFFF;
    width: 50px;
height: 50px;
background: rgba(255, 255, 255, 0.1);
border-radius: 25px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}
.faqs__section .faqs .faq .faq__head div span {
    /* inear-gradient(90deg, #2376D5 12.93%, #9412DD 88.21%);
 */
 color: #2376D5;
 margin-bottom: 10px;
}
.faqs__section .faqs .faq .faq__head div h5 {
    color: #FFFFFF;
    margin-left: 10px;
}

.faqs__section .faqs .faq .faq__body {
    padding: 0 20px 20px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .faqs__section .faqs .faq {
        width: 100%;
    }
    .faqs__section .faqs {
        padding: 0 20px 100px;
    }
}

@media (max-width: 600px) {
    .faqs__section .faqs .faq .faq__head div span {
        font-size: 12px;
    }
}