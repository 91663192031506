.overview__section,
.exchange__section,
.features__section,
.how__itWorks,
.wealth__pass,
.faqs__section {
  background-color: #12012a;
    padding: 100px 0 0;
  position: relative;
}
.overview__section .vector__avatar {
  position: absolute;
  left: 27.71%;
  right: 38.96%;
  top: 18.62%;
  bottom: 89.39%;
  opacity: 0.1;
  z-index: 0;
}
.section__head {
  text-align: center;
  background-image: url("../../../assets/images/overview__bg.svg");
  width: 848px;
  height: 170px;
  background-repeat: no-repeat;
  margin: auto;
  padding: 20px 0;
  z-index: 1;
  background-position: center;
}
.section__head h3 {
  font-weight: 700;
  font-size: 49.23px;
  line-height: 63px;
  letter-spacing: 12.7571px;
  background: linear-gradient(90deg, #2376d5 12.93%, #9412dd 88.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.separate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px;
}
.separate div {
  width: 756px;
}
.separate h5,
.description h5 {
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  letter-spacing: 0.257143px;
  color: #ffffff;
  margin-bottom: 40px;
}
.separate p,
.overview__section .description p {
  font-weight: 600;
  font-size: 20px;
  line-height: 174.9%;
  color: rgba(255, 255, 255, 0.8);
}

.overview__section .description {
  display: flex;
  padding: 100px 0 100px 100px;
  justify-content: space-between;
  /* align-items: center; */
}
.overview__section .description div {
  width: 636px;
}

@media (max-width:912px) {
  .section__head {
    width: 100%;
  }
  .separate {
    flex-direction: column;
    padding: 100px 20px;
  }
  .separate img {
    width: 100%;
    object-fit: contain;
  }
  .separate div,
  .overview__section .description div {
    width: 100%;
  }
  .description {
    flex-direction: column;
  }
  .overview__section .description {
    padding: 100px 20px;

  }
  .separate p, .overview__section .description p {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .separate {
    padding: 100px 20px;
  }
  .overview__section .description {
    padding: 100px 0px 100px 20px;
  }
}