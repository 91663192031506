.wealth__pass {
  padding: 200px 0;
}
.wealth__pass .section__head {
  background-image: url("../../../../public/images/wealth__pass.svg");
  width: 1158px;
}
.wealth__pass .separate div h6 {
  background: linear-gradient(90deg, #2376d5 12.93%, #9412dd 88.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 25px;
  line-height: 143.6%;
  letter-spacing: 0.257143px;
  width: 534px;
  height: 72px;
  margin-bottom: 30px;
}

@media (max-width: 912px) {
  .wealth__pass .section__head {
    width: 100%;
  }
  .wealth__pass .section__head h3 {
    font-size: 30px !important;
    line-height: 30px !important;
  }
  .wealth__pass .separate div h6 {
    width: 100% !important;
  }
  .separate h5, .description h5{
    font-size: 30px !important;
  }
  .faqs__section .faqs .faq {
    width: 100%;
    padding: 0 2px;
  }
  .faqs__section .faqs .faq .faq__head div {
    width: 70%;
  }
  .faqs__section .faqs .faq .faq__head div h5 {
    
    font-size: 16px;
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .wealth__pass .section__head {
    width: 100%;
  }
}