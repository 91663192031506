.privary__section {
  padding-top: 100px;
}

.privary__section .hero__section {
  background: linear-gradient(90deg, #ffc200 0%, #f40156 100%);
  opacity: 0.8;
  padding: 200px 100px;
  color: var(--sub-white-color);
  text-align: left;
}
.privary__section .hero__section i {
  font-size: 60px;
}
.privary__section .hero__section h3 {
  font-weight: 700;
  font-size: 68px;
  line-height: 76px;
  margin: 20px 0;
}
.privary__section .hero__section p {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  width: 964px;
  margin: 0;
}

.how__section {
  padding: 100px;
  background: #111126;
}

.how__section .how__top {
  color: var(--sub-white-color);
  text-align: center;
}
.how__section .how__top h3,
.how__section .how__type h3,
.how__updates h3 {
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  width: 714px;
  height: 96px;
  margin: 50px auto;
}
.how__section .how__top p,
.how__information p,
.how__updates p {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  width: 964px;
  height: 96px;
  margin: 0 auto;
}
.how__section .how__type {
  width: 964px;
  margin: 300px auto 0;
  color: var(--sub-white-color);
}
.how__section .how__type h3 {
  margin: 0 auto;
  text-align: center;
}
.how__section .how__type p {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 50px;
}

.how__information {
  background: #1e0736;
  /* opacity: 0.1; */
  padding: 200px 0 0;
  text-align: center;
  color: var(--sub-white-color);
  height: 1165px;
}
.how__information h3 {
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  margin: 50px 0;
}
.how__information p {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  height: 0;
}

.how__updates {
  background: #111126;
  margin-top: -20px;
  color: var(--sub-white-color);
  padding: 100px 0;
  height: 1180px;
}
.how__updates h3 {
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
}
.how__updates p {
  text-align: center;
}
.how__updates img {
  width: 100%;
  margin-top: 100px;
}

@media (max-width: 912px) {
  .privary__section .hero__section {
    padding: 50px 20px;
    margin-top: -40px;
  }
  .hero__section h3 {
    font-size: 30px;
  }
  .hero__section p {
    font-size: 18px;
    width: 100%;
  }
  .how__section {
    padding: 50px 20px;
  }
  .how__section .how__top h3,
  .how__section .how__type h3,
  .how__updates h3 {
    font-size: 28px;
    width: 100%;
    line-height: 40px;
  }

  .how__section .how__top p,
  .how__information p,
  .how__updates p,
  .how__section .how__type p {
    font-size: 18px;
    width: 100%;
    margin-top: 40px;
  }
  .how__section .how__type {
    width: 100%;
  }
  .how__information {
    padding: 200px 20px 0;
    min-height: 1765px;
  }

  .how__updates {
    padding: 100px 20px 0;
    height: 700px;
  }
  .how__information h3 {
    font-size: 24px;
  }
}

@media (max-width: 379px) {
  .how__section .how__top h3 {
    margin-bottom: 100px;
  }
}

@media (max-width: 280px) {
  .how__information {
    padding: 200px 20px 0;
    min-height: 2365px;
  }
  .how__updates {
    height: 800px;
  }
  .how__section .how__top p,
  .how__information p,
  .how__updates p,
  .how__section .how__type p {
    font-size: 16px;
  }
  .how__section .how__top h3 {
    margin-bottom: 140px;
  }
  .hero__section {
    padding: 200px 20px;
  }
}

@media (min-width: 760px) and (max-width: 912px) {
  .how__section .how__type {
    margin: 100px auto 0;
  }
  .how__information {
    padding: 100px 20px 0;
    min-height: 900px;
  }
}
