.features__section {
  padding: 100px 0;
  position: relative;
  min-height: 500px;
}
.features__section .section__head {
  background-image: url("../../../../public/images/features__bg.svg");
}
.features__section .features__abs {
  position: absolute;
  right: 0;
  top: 1%;
}
.features__section .separate h6 {
  background: linear-gradient(90deg, #2376d5 12.93%, #9412dd 88.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  letter-spacing: 0.257143px;
  width: 438px !important;
  height: 86px;
  margin-bottom: 30px;
}
.features__section .separate div p {
  width: 772px;
}
.features__section .lines {
  position: absolute;
  top: 38%;
}
.features__section .description {
  padding: 320px 100px 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.features__section .description div {
  width: 707px;
}
.features__section .description div p {
  font-weight: 600;
  font-size: 20px;
  line-height: 174.9%;
  /* or 35px */
  color: rgba(255, 255, 255, 0.8);
}
.features__section .features__sol {
    position: absolute;
    right: 0;
    bottom: -150px;
}
.features__section .built__sol {
    position: absolute;
    right: 120px;
    bottom: 100px;
}

@media (max-width: 912px) {
  .section__head h3 {
    font-size: 30px !important;
  }
  .features__section .separate h6 {
    width: 100% !important;
  }
  .features__section .separate img {
    margin-bottom: 50px;
  }
  .features__section .separate div p {
    width: 100%;
  }
  .features__section .lines,
  .features__section .description img,
  .features__section .description div {
    width: 100%;
  }
  .features__section .description {
    padding: 100px 20px;
    flex-direction: column-reverse;
    position: relative;
  }
  .features__section .built__sol {
    top: 250px;
    right: 0;
    width: 200px !important;
  }
  .features__section .features__sol {
    top: 150px
  }
  .features__section .description div {
    margin-top: 50px;
  }
}

@media (min-width: 712px) and (max-width: 912px) {
  .features__section .built__sol {
    top: -200px;
    right: 20%;
  }
  .features__section .features__sol {
    top: -400px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .features__section .separate div {
    width: 50%;
  } 
  .features__section .separate div p {
    width: 100%;
  }
  .features__section .lines {
    width: 100%;
  }
  .features__section .description {
    padding: 100px 20px;
  }
}