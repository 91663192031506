.exchange__section {
  position: relative;
}
.exchange__section .exchange__abs {
  position: absolute;
  top: 5%;
  right: 0;
}
.exchange__section .section__head {
  background: url("../../../../public/images/exchange__bg.svg");
  background-repeat: no-repeat;
}
.exchange__section .description {
  display: flex;
  justify-content: space-between;
  padding: 100px;
}
.exchange__section .description div {
  width: 772px;
}
.exchange__section .description h5 {
  width: 707px;
  height: 102px;
}
.exchange__section .description p {
  font-weight: 600;
  font-size: 20px;
  line-height: 174.9%;
  /* or 35px */
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 912px) {
  .exchange__section .description {
    flex-direction: column-reverse;
    padding: 100px 20px;
  }
  .exchange__section .description div {
    width: 100%;
  }
  .exchange__section .description h5 {
    width: 100%;
    font-size: 30px;
    line-height: 30px;
    margin-top: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .separate img {
    width: 40%;
  }
  .exchange__section .description {
    padding: 100px 20px;
  }
  .exchange__section .description div {
    width: 50%;
  }
}