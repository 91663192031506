.hero__section {
  background-image: url("../../../assets/images/bg.svg");
  text-align: center;
  padding: 250px 100px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero__section h3 {
  font-weight: 700;
  font-size: 49.23px;
  line-height: 63px;
  letter-spacing: 0.257143px;
  color: #ffffff;
  width: 793px;
  height: 126px;
  margin: auto;
}
.hero__section p {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.8);
  width: 594px;
  height: 33px;
  margin: 20px auto 40px;
}
.hero__section button {
  background: #6c4de6;
  box-shadow: 0px 4px 16px rgba(108, 77, 230, 0.5);
  border-radius: 8px;
  width: 194px;
  height: 58px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.257143px;
  color: #ffffff;
}




@media (max-width: 912px) {
  .hero__section {
    padding: 250px 20px;
  }
  .hero__section h3 {
    width: 100%;
    line-height: 35px;
    height: 100px;
  }
  .hero__section p {
    margin: 50px 0 80px;
  }
} 

@media (min-width: 712px) and (max-width: 912px) {
  .separate {
    /* align-items: flex-start !important; */
  }
  .separate img, .description >img {
    width: 400px !important;
    margin: auto;
  }
}