nav {
  padding: 20px 100px;
  position: fixed;
  top: 0;
  background: #12012a;
  z-index: 1000;
  width: 100%;
}
nav .navbar__lg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .nav__brand, .offcanvas.show .nav__brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .nav__brand i, .offcanvas.show .nav__brand i {
  color: #ffffff;
  font-size: 30px;
  display: none;
}
nav .navbar__lg ul {
  display: flex;
  align-items: center;
}
nav .navbar__lg ul li {
  list-style: none;
  transition: transform 0.2s;
  margin-left: 10px;
}
nav .navbar__lg ul li:hover {
  transform: scale(1.2);
}
nav .navbar__lg ul li a {
  text-decoration: none;
  color: #ffffff;
  margin-left: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
}
nav .navbar__lg ul li a button, .offcanvas.show ul li a button {
  border: 2px solid #6c4de6;
  filter: drop-shadow(0px 4px 16px rgba(108, 77, 230, 0.5));
  border-radius: 8px;
  width: 152px;
  height: 58px;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  letter-spacing: 0.257143px;
}

.offcanvas.show {
  height: 100%;
  background: #12012a;
  color: #ffffff;
}
.offcanvas.show .nav__links ul {
    padding-left: 10px;
}
.offcanvas.show .nav__links ul li {
    list-style: none;
    margin-bottom: 20px;
}
.offcanvas.show .nav__links ul li a {
    color: #ffffff;
    text-decoration: none;
}
.btn-close {
    display: none;
}

@media (max-width: 1024px) {
  nav {
    padding: 20px;
  }
  nav .nav__links {
    display: none;
  }
  nav img {
    width: 100px;
  }
  nav .nav__brand, .offcanvas-title {
    width: 100%;
  }
  nav .nav__brand i, .offcanvas.show .nav__brand i {
    display: block;
  }
}

@media (min-width: 1280px) and (max-width: 1280px) {
  nav {
    padding: 20px;
  }
}
