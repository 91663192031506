.how__itWorks {
  padding: 100px;
}
.how__itWorks .section__head {
  background-image: url("../../../../public/images/howitworks__bg.svg");
  background-repeat: no-repeat;
  width: 1255px;
  height: 170px;
}
.how__itWorks .works {
    margin-top: 50px;
}
.how__itWorks .works .work {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 40px;
    width: 1083px;
    margin: auto;
    min-height: 400px !important;
}
.how__itWorks .works .work p {
    width: 828px;
    min-height: 186px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
font-size: 18px;
line-height: 174.9%;
margin-top: 20px;
}
.how__itWorks .works .one {
    background: #1d0031
/* opacity: 0.7; */
}
.how__itWorks .works .two {
    background: #2f0051;
}

@media (max-width: 1024px) {
    .how__itWorks {
        padding: 20px;
    }
    .how__itWorks .section__head {
        width: 100%;
        padding: 20px;
    }
    .how__itWorks .section__head h3 {
        font-size: 30px !important;
        line-height: 30px;
        margin-top: 40px;
    }
    .how__itWorks .works .work {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 20px;
        
    }
    .how__itWorks .works .work p {
        width: 100%;
    }
}