.whitepaper__section {
  color: var(--sub-white-color);
}
.whitepaper__section .hero__section {
  background: linear-gradient(180deg, #41D1FF 0%, rgba(0, 56, 255, 0.87) 100%);
  opacity: 0.8;
  padding: 200px 100px;
}
.whitepaper__section .hero__section h3 {
  font-weight: 700;
  font-size: 68px;
  line-height: 10px;
  margin-bottom: 50px;
  margin-top: 50px;
  width: 100%;
}
.whitepaper__section .hero__section p {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  width: 964px;
  margin-top: 50px 0;
}

.whitepaper__section .main__section {
  background: #13142c;
  padding: 200px 100px;
}

.whitepaper__section .main__section .launchpad .left {
  background: #1d0a43;
  border-radius: 50px 0px 0px 50px;
  padding-top: 40px !important;
}
.whitepaper__section .main__section .launchpad .right {
  background: linear-gradient(90deg, #5f72ff 0%, #73d6f1 100%);
  border-radius: 0px 50px 50px 0px;
}
.whitepaper__section .main__section .global__group .left {
  background: #2c0347;
  border-radius: 50px 0px 0px 50px;
  padding-top: 40px !important;
}
.whitepaper__section .main__section .global__group .right {
  background: linear-gradient(90deg, #5f72ff 0%, #73d6f1 100%);
  border-radius: 0px 50px 50px 0px;
}
.whitepaper__section .main__section .utta__token .left {
  background: #391b2c;
  border-radius: 50px 0px 0px 50px;
  padding-top: 40px !important;
}
.whitepaper__section .main__section .utta__token .right {
  background: linear-gradient(90deg, #ffc200 0%, #f40156 100%);
  border-radius: 0px 50px 50px 0px;
}
.whitepaper__section .main__section .divit__token .left {
  background: linear-gradient(
    90deg,
    #595754 7.91%,
    #595754 7.91%,
    #211926 63.23%,
    #211926 85.01%,
    #211926 107.68%
  );
  border-radius: 50px 0px 0px 50px;
  padding-top: 40px !important;
}
.whitepaper__section .main__section .divit__token .right {
  background: linear-gradient(
    90deg,
    #b88717 -5.35%,
    #ffd166 8.48%,
    #f5d281 27.91%,
    #6b5011 63.23%,
    #6b5011 85.01%,
    #c48e0e 107.68%
  );
  border-radius: 0px 50px 50px 0px;
}
.whitepaper__section .main__section .ultainfinity__wealth .left {
  background: #2c0347;
  border-radius: 50px 0px 0px 50px;
  padding-top: 40px !important;
}
.whitepaper__section .main__section .ultainfinity__wealth .right {
  background: linear-gradient(80.73deg, #730dc5 -7.35%, #d34e76 55.84%);
  border-radius: 0px 50px 50px 0px;
}
.whitepaper__section .main__section .ultainfinity__exchange .left {
  background: #2c0347;
  border-radius: 50px 0px 0px 50px;
  padding-top: 40px !important;
}
.whitepaper__section .main__section .one__step {
    height: 500px;
}
.whitepaper__section .main__section .one__stepID .left {
    background: #372e35;
border-radius: 50px 0px 0px 50px;
padding-top: 30px;
}

/* #2c0347; */
.whitepaper__section .main__section .one__stepID .left p {
    margin-top: -30px;
}
.whitepaper__section .main__section .one__stepID .right {
    background-image: url("../../assets/one.svg");
    background-repeat: no-repeat;
    border-radius: 0px 50px 50px 0px;
}
.whitepaper__section .main__section .instant .left {
    background: #2c0347;
border-radius: 50px 0px 0px 50px;
padding-top: 40px !important;
}
.whitepaper__section .main__section .main__card {
  width: 971px;
  margin: 0 auto 150px;
  display: flex;
  /* height: 500px; */
}
.whitepaper__section .main__section .main__card .left h5 {
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  width: 282px;
  height: 144px;
  margin-bottom: 30px;
}
.whitepaper__section .main__section .main__card .left p {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 339px;
  margin-bottom: 30px;
  /* opacity: 100; */
}
.whitepaper__section .main__section .main__card .left span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.whitepaper__section .main__section .main__card .left {
  padding: 10px 0 50px 70px;
}
.whitepaper__section .main__section .main__card .left,
.whitepaper__section .main__section .main__card .right {
  width: 50%;
}
.whitepaper__section .main__section .main__card .right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.whitepaper__section .main__section .ultainfinity__exchange .left{
    padding: 10px 0 30px 70px;
}
.whitepaper__section .main__section .instant .left {
    padding: 0 0 20px 70px;
}
.whitepaper__section .main__section .one__stepID h5 {
    margin-top: 20px;
}
.whitepaper__section .group__image {
    background: #13142c;
    padding-bottom: 200px;
}
.whitepaper__section .group__image img {
    width: 100%;
}

@media (max-width: 1024px) {
    .whitepaper__section .hero__section,
    .whitepaper__section .main__section {
        padding: 200px 20px;
    }
}

@media (max-width: 912px) {
    .whitepaper__section .hero__section p {
        width: 100%;
        font-size: 18px;
    }
    .whitepaper__section .hero__section h3 {
        font-size: 32px;
    }
    .whitepaper__section .main__section .main__card {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        width: 100%;
    }
    .whitepaper__section .main__section .main__card .right {
        border-radius: 50px 50px 0 0;
        width: 100%;
        padding: 20px 0;
    }
    .whitepaper__section .main__section .main__card .right img {
        width: 90px;
        height: 100px;
    }
    .whitepaper__section .main__section .main__card .left {
        padding: 20px;
        border-radius: 0 0 50px 50px;
        width: 100%;
    }
    .whitepaper__section .main__section .main__card .left p {
        width: 100%;
    }
    .whitepaper__section .main__section .main__card .left h5 {
        width: 100%;
        font-size: 24px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: -20px;
    }
    .whitepaper__section .main__section .ultainfinity__exchange .right {
        width: 100%;
    }
   
}

@media (min-width: 280px) and (max-width: 280px) {
    .whitepaper__section .main__section .main__card .right {
        border-radius: 50px 50px 0 0;
        width: 100%;
        padding: 20px 0;
    }
}

@media (min-width: 712px) and (max-width: 912px) {
    .whitepaper__section .main__section .main__card .left {
        padding: 20px;
        border-radius: 0 0 50px 50px;
        width: 100%;
    }
    .whitepaper__section .main__section .one__stepID .right {
        width: 100%;
        background-size: cover;
    }
}

@media (min-width: 540px) and (max-width: 540px) {
    .whitepaper__section .main__section .main__card .left {
        width: 100%;
    }
}