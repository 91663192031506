@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap");

.roadmap__hero {
  background-image: url("../../assets/Roadmaps/background__oneee.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 250px 100px 500px;
  font-family: "Nunito Sans", sans-serif;
  position: relative;
}
.roadmap__hero h5 {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: #f1e46c;
}
.roadmap__hero h1 {
  font-weight: 800;
  font-size: 45px;
  line-height: 61px;
  color: #ffffff;
}

.roadmap__release {
  text-align: left;
  margin-top: 200px;
  /* position: absolute; */
  bottom: 100px;
  position: relative;
}
.release__head {
  display: flex;
  align-items: center;
}
.release__head > div {
  background: #ffffff;
  width: 23px;
  height: 23px;
  border-radius: 100px;
  margin-right: 20px;
}
.release__head > h5 {
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  margin-top: 20px !important;
}
.roadmap__release .release__text p {
  width: 707px;
  height: 60px;
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-top: 10px;
}
.roadmap__release .release__phases {
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 200px;
}
.roadmap__release .release__phases .release__phase {
  width: 15%;
  position: relative;
}
.roadmap__release .release__phases .release__phase p {
  position: absolute;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #00deff;
  bottom: 140px;
}
.roadmap__release .release__phase .active__phase {
  position: absolute;
  bottom: 200px;
  left: 60px;
  background: #6de0f6;
  opacity: 0.2;
  border-radius: 100px;
}
.roadmap__release .release__phases .one p,
.roadmap__release .release__phases .two p,
.roadmap__release .release__phases .three p {
  left: 50px;
}
.roadmap__release .release__phases .four p,
.roadmap__release .release__phases .five p,
.roadmap__release .release__phases .six p {
  right: 70px;
}

.roadmap__schedule,
.schedule__stages,
.roadmap__roadmap,
.development__stages {
  padding: 200px 100px 100px;
  background: #001240;
}
.roadmap__schedule .schedule__text {
  margin-top: 50px;
}
.roadmap__schedule .schedule__text p {
  width: 1087px;
  /* height: 270px; */
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
}
.roadmap__schedule .schedules {
  margin-top: 100px;
}
.roadmap__schedule .schedules .schedule__top,
.roadmap__schedule .schedules .schedule__bottom {
  display: flex;
}
.roadmap__schedule .schedules .schedule__top .schedule,
.roadmap__schedule .schedule__bottom .schedule {
  width: 310px;
  min-height: 256px;
  padding: 20px 50px;
  position: relative;
}
.roadmap__schedule .schedules .border {
  background-color: linear-gradient(
    180deg,
    #6de0f6 0%,
    rgba(250, 255, 0, 0.87) 55.47%,
    #ff8a00 100%
  ) !important;
}
.roadmap__schedule .schedules .schedule__top .one,
.roadmap__schedule .schedule__bottom .schedule__one {
  background: linear-gradient(
    55.96deg,
    rgba(0, 148, 255, 0.0526381) 18.75%,
    #0094ff 95.66%
  );
  /* border-left: 1px solid #ff8a00; */
}
.roadmap__schedule .schedules .schedule h5 {
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 20px;
}
.roadmap__schedule .schedules .schedule p {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}
.roadmap__schedule .schedules .two p {
  width: 278px;
}
.roadmap__schedule .schedules .three p {
  width: 262px;
}
.roadmap__schedule .schedules .bottom__paragraph {
  position: absolute;
  bottom: 10px;
  width: 141px;
  height: 37px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 98.4%;
  color: #ffffff;
}
.roadmap__schedule .schedules .buy__token {
  background: linear-gradient(90deg, #5f72ff 0%, #73d6f1 100%);
}
.roadmap__schedule .schedules .coming__soon {
  background: linear-gradient(90deg, #5f72ff 0%, #73d6f1 100%);
  opacity: 0.5;
}
.roadmap__schedule .schedules .border__one {
  position: absolute;
  height: 572px;
}

.schedule__stages .stages__image {
  margin-top: 100px;
  position: relative;
}
.schedule__stages .stages__image p {
  color: #ffffff;
  position: absolute;
  font-weight: 600;
}
.schedule__stages .stages__image .s5 {
  font-size: 100px;
  line-height: 136px;
  left: 150px;
  top: -50px;
}
.schedule__stages .stages__image .s4 {
  font-size: 80px;
  line-height: 109px;
  left: 40%;
  top: 10px;
}
.schedule__stages .stages__image .s3 {
  font-size: 60px;
  line-height: 82px;
  right: 410px;
  top: 60px;
}
.schedule__stages .stages__image .s2 {
  font-size: 40px;
  line-height: 55px;
  right: 240px;
  top: 100px;
}
.schedule__stages .stages__image .s1 {
  font-size: 25px;
  line-height: 34px;
  right: 120px;
  top: 140px;
}
.schedule__stages .stages__image .s5__price {
  font-size: 55px;
  line-height: 75px;
  left: 110px;
  top: 400px;
}
.schedule__stages .stages__image .s1__price {
  font-size: 35px;
  line-height: 48px;
  opacity: 0.7;
  right: 120px;
  top: 280px;
}
.schedule__stages .stages__image .line__image {
  position: absolute;
  left: 30%;
  top: 320px;
}

.roadmap__roadmap {
  padding-bottom: 600px;
}
.roadmap__roadmap .roadmaps h3 {
  font-weight: 800;
  font-size: 35px;
  line-height: 48px;
  color: #ffffff;
  margin-top: 50px;
}
.roadmaps .roadmap__cards {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.roadmaps .roadmap__card {
  margin-top: 50px;
  color: #ffffff;
  width: 20%;
}
.roadmap__cards img {
  position: absolute;
}
.roadmap__cards .top__line,
.roadmap__cards .bottom__line {
  left: 38%;
}
.roadmap__cards .top__line {
  bottom: 240px;
}
.roadmap__cards .bottom__line {
  top: 120px;
}
.roadmap__cards .straight__line {
  top: 100px;
}
.roadmaps .roadmap__card h5 {
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  opacity: 0.8;
}
.roadmaps .roadmap__card ul {
  padding-left: 0;
  margin-top: 50px;
}
.roadmaps .roadmap__card ul li {
  list-style: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* height: 150px; */
}
.may ul li {
  width: 188px;
}
.june ul li {
  width: 190px;
}
.july ul li,
.september ul li {
  width: 176px;
}
.august ul li {
  width: 225px;
}
.roadmaps__section .wealth {
  padding: 170px 0 400px 0;
  margin-top: -95px;
  clip-path: 0;
}

/* 
    Development Stages
*/
.development__stages .development__stage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.development__stages .reverse__stage {
    flex-direction: row-reverse;
}
.development__stage .light {
  width: 357px;
  height: 350px;
  border: 10px solid #6a97d4;
  border-radius: 247.979px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fcfcfd;
  padding: 50px !important;
}
.development__stage .light img {
  margin: auto;
}
.development__stage .light p {
  font-weight: 800;
  font-size: 20px;
  line-height: 41px;
  width: 100%;
  height: 82px;
  text-align: center;
}
.development__stage  .six .light p {
  font-size: 18px !important;
}
.development__stage .stage__details {
  width: 580px;
  height: 693px;
  color: #ffffff;
}
.development__stage .stage__details h3 {
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
}
.development__stage .stage__details .details .sub {
  display: flex;
  align-items: center;
  color: #f8f8f9;
  margin-bottom: 40px;
}
.development__stage .stage__details .details .sub h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  margin-top: 20px !important;
}
.development__stage .stage__details .details .sub div {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 100px;
  margin-right: 20px;
}
.development__stage .stage__details .details p {
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  margin-top: -30px;
}
.development__stages .development__image {
    text-align: center;
    /* margin: 300px 0; */
  }
  .development__stages .development__image  img {
  margin: 0 auto !important;

}
.development__stages .development__more {
    margin: 700px 0 100px;
}
.development__stages .one {
    margin-bottom: 200px;
}
.development__stages .two {
    margin-top: 100px;
}
.development__stages .three {
    margin-bottom: 200px;
}
.development__stages .four {
    margin-top: 100px;
    margin-bottom: 200px;
}
.development__stages .five {
    margin-top: 100px;
}
.development__stages .five .stage__details {
  margin-top: 100px;
}
.development__stages .six {
    margin-top: 100px;
}
.development__stages .six .light {
  padding: 0 20px !important;
  margin-top: -200px;
}
.development__stages .six .light img {
  width: 100px;
  margin-top: -80px;
  margin-bottom: 20px;
}
.development__stages .seven {
    margin-top: 100px;
}
.development__stages .seven .light {
  margin-top: -400px;
}
.roadmap__cards .straight__line {
    width: 100%;
    top: 150px;
}
.development__stages ul li {
  list-style-type: circle;
}


@media (max-width: 969px) {
  .roadmap__hero {
    padding: 150px 20px 400px;
  }
  .roadmap__hero h1 {
    line-height: 40px;
    font-size: 30px;
  }
  .roadmap__release .release__text p {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .roadmap__release .release__phases .release__phase p {
    font-size: 8px;
    bottom: 0;
  }
  .roadmap__release .release__phases .four p,
  .roadmap__release .release__phases .five p,
  .roadmap__release .release__phases .six p {
    right: 8px;
  }
  .roadmap__release .release__phases .one p,
  .roadmap__release .release__phases .two p,
  .roadmap__release .release__phases .three p {
    left: 5px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 70px;
    left: 20px;
    width: 15px;
    height: 15px;
  }
  .roadmap__schedule,
  .schedule__stages,
  .roadmap__roadmap,
  .development__stages {
    padding: 100px 20px;
  }
  .roadmap__schedule .schedule__text p {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .roadmap__schedule .schedules .schedule__top,
  .roadmap__schedule .schedules .schedule__bottom {
    flex-direction: column;
  }
  .roadmap__schedule .schedules .schedule__top .schedule,
  .roadmap__schedule .schedule__bottom .schedule {
    width: 100%;
    padding: 20px;
  }
  .schedules img {
    display: none;
  }
  .release__head > h5 {
    font-size: 24px;
    line-height: 24px;
  }
  .roadmap__schedule .schedules .schedule p {
    font-size: 16px;
  }
  .roadmap__cards img {
    display: none;
  }
  .roadmaps .roadmap__cards {
    flex-direction: column;
  }
  .roadmap__roadmap {
    padding-bottom: 400px;
  }
  .roadmaps .roadmap__card ul {
    margin-top: 20px;
  }
  .roadmaps .roadmap__card ul li {
    font-size: 16px;
  }
  .schedule__stages .stages__image .s5 {
    font-size: 24px;
    left: 28px;
    top: -65px;
  }
  .schedule__stages .stages__image .s4 {
    font-size: 20px;
    left: 44%;
    top: -40px;
  }
  .schedule__stages .stages__image .s3 {
    font-size: 16px;
    right: 27%;
    top: -20px;
  }
  .schedule__stages .stages__image .s2 {
    font-size: 12px;
    line-height: 55px;
    right: 12%;
    top: 0;
  }
  .schedule__stages .stages__image .s1 {
    font-size: 8px;
    line-height: 34px;
    right: 4px;
    top: 16px;
  }
  .schedule__stages .stages__image .s5__price {
    font-size: 20px;
    line-height: 75px;
    left: 10px;
    top: 70px;
  }
  .schedule__stages .stages__image .s1__price {
    font-size: 12px;
    line-height: 48px;
    opacity: 0.7;
    right: -10px;
    top: 60px;
  }
  .schedule__stages .stages__image .line__image {
    position: absolute;
    left: 40%;
    top: 90px;
    width: 80px;
  }

  .development__stage ul li {
    font-size: 18px;
    line-height: 27px;
    width: 100%;
  }
  .development__stages .hor__line {
    margin: 0;
    width: 100%;
    position: absolute;
  }
  .development__stage .inner__stage {
    padding: 20px 10px;
  }
  .roadmap__schedule .schedules p {
    width: 100% !important;
  }
  .development__stages .five .stage__details {
    margin-top: 0px;
  }
}

@media (min-width: 280px) and (max-width: 280px) {
  .roadmap__release .release__phase .active__phase {
    bottom: 40px;
    left: 12px;
  }
  .roadmap__hero h1 {
    font-size: 30px;
  }
  .roadmaps__section .wealth {
    padding: 170px 0px 0;
    margin-top: -95px;
    clip-path: 0;
  }
  .development__stage ul li {
    font-size: 18px;
    line-height: 27px;
    width: 100%;
  }
  .development__stages .hor__line {
    margin: 0;
    width: 100%;
    position: absolute;
  }
  .roadmap__schedule .schedules .two p {
    width: 100%;
  }
}

@media (min-width: 360px) and (max-width: 540px) {
  .roadmap__release .release__phases .release__phase p {
    font-size: 8px;
    bottom: 19px;
  }
  .roadmap__release .release__phases .one p,
  .roadmap__release .release__phases .two p,
  .roadmap__release .release__phases .three p {
    left: 12px;
  }
  .roadmap__release .release__phases .four p,
  .roadmap__release .release__phases .five p,
  .roadmap__release .release__phases .six p {
    right: 12px;
  }
  .schedule__stages .stages__image .s5 {
    left: 48px;
  }
  .schedule__stages .stages__image .s4 {
    top: -35px;
  }
  .schedule__stages .stages__image .s3 {
    top: -10px;
  }
  .schedule__stages .stages__image .s2 {
    right: 13%;
    top: 13px;
  }
  .schedule__stages .stages__image .s1 {
    right: 8px;
    top: 26px;
  }
  .schedule__stages .stages__image .s5__price {
    font-size: 20px;
    line-height: 75px;
    left: 24px;
    top: 100px;
  }
  .schedule__stages .stages__image .s1__price {
    right: -10px;
    top: 80px;
  }
  .schedule__stages .stages__image .line__image {
    left: 35%;
    top: 110px;
    width: 150px;
  }
  .development__stage ul li {
    font-size: 18px;
    line-height: 27px;
    width: 100%;
  }
  .development__stages .hor__line {
    margin: 0;
    width: 100%;
    position: absolute;
  }
}

@media (min-width: 540px) and (max-width: 540px) {
  .roadmap__release .release__phases .release__phase p {
    font-size: 16px;
    bottom: 40px;
  }
  .schedule__stages .stages__image .s5 {
    font-size: 24px;
    left: 78px;
    top: -65px;
  }
  .schedule__stages .stages__image .s4 {
    font-size: 20px;
    left: 47%;
    top: -30px;
  }
  .schedule__stages .stages__image .s3 {
    font-size: 16px;
    right: 28%;
    top: 5px;
  }
  .schedule__stages .stages__image .s2 {
    font-size: 12px;
    line-height: 55px;
    right: 14%;
    top: 28px;
  }
  .schedule__stages .stages__image .s1 {
    font-size: 8px;
    line-height: 34px;
    right: 13px;
    top: 46px;
  }
  .schedule__stages .stages__image .s5__price {
    font-size: 20px;
    line-height: 75px;
    left: 50px;
    top: 150px;
  }
  .schedule__stages .stages__image .s1__price {
    font-size: 12px;
    line-height: 48px;
    opacity: 0.7;
    right: -10px;
    top: 110px;
  }
  .schedule__stages .stages__image .line__image {
    position: absolute;
    left: 35%;
    top: 140px;
    width: 240px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 90px;
    left: 20px;
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 912px) {
  .roadmap__release .release__phases .release__phase p {
    font-size: 24px;
    bottom: 70px;
  }
  .roadmap__release .release__phases .one p,
  .roadmap__release .release__phases .two p,
  .roadmap__release .release__phases .three p {
    left: 30px;
  }
  .roadmap__release .release__phases .four p,
  .roadmap__release .release__phases .five p,
  .roadmap__release .release__phases .six p {
    right: 30px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 120px;
    left: 40px;
    width: 45px;
    height: 45px;
  }
  .roadmap__schedule .schedules .schedule__top .schedule,
  .roadmap__schedule .schedule__bottom .schedule {
    width: 310px;
  }
  .roadmap__schedule .schedules .schedule__top,
  .roadmap__schedule .schedules .schedule__bottom {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  .roadmaps .roadmap__cards {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .roadmaps .roadmap__card {
    width: 33%;
  }
  .schedule__stages .stages__image .s5 {
    font-size: 64px;
    left: 110px;
    top: -65px;
  }
  .schedule__stages .stages__image .s4 {
    font-size: 50px;
    left: 45%;
    top: -10px;
  }
  .schedule__stages .stages__image .s3 {
    font-size: 40px;
    right: 27%;
    top: 25px;
  }
  .schedule__stages .stages__image .s2 {
    font-size: 30px;
    right: 12.5%;
    top: 60px;
  }
  .schedule__stages .stages__image .s1 {
    font-size: 20px;
    right: 10px;
    top: 80px;
  }
  .schedule__stages .stages__image .s5__price {
    font-size: 50px;
    line-height: 75px;
    left: 50px;
    top: 260px;
  }
  .schedule__stages .stages__image .s1__price {
    font-size: 30px;
    line-height: 48px;
    opacity: 0.7;
    right: -10px;
    top: 200px;
  }
  .schedule__stages .stages__image .line__image {
    position: absolute;
    left: 260px;
    top: 230px;
    width: 350px;
  }
  .schedule__stages {
    padding-bottom: 150px;
  }
  .development__stage ul li {
    font-size: 18px;
    line-height: 27px;
    width: 100%;
  }
  .development__stages .hor__line {
    margin: 0;
    width: 100%;
    position: absolute;
  }
 
}

@media (min-width: 912px) and (max-width: 912px) {
  .schedule__stages .stages__image .s5__price {
    font-size: 50px;
    line-height: 75px;
    left: 50px;
    top: 300px;
  }
  .schedule__stages .stages__image .s1__price {
    font-size: 30px;
    line-height: 48px;
    opacity: 0.7;
    right: -10px;
    top: 230px;
  }
  .schedule__stages .stages__image .line__image {
    position: absolute;
    left: 270px;
    top: 250px;
    width: 460px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .roadmap__hero {
    padding: 150px 20px 400px;
  }
  .roadmap__schedule,
  .schedule__stages,
  .roadmap__roadmap,
  .development__stages {
    padding: 100px 20px;
  }
  .roadmap__roadmap {
    padding: 100px 20px 300px;
  }
  .schedule__stages {
    padding-bottom: 200px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 160px;
    left: 60px;
  }
  .roadmap__release .release__phases .release__phase p {
    bottom: 110px;
  }
  .roadmap__schedule .schedule__text p {
    width: 100%;
  }
  .roadmap__release .release__phases .four p,
  .roadmap__release .release__phases .five p,
  .roadmap__release .release__phases .six p {
    right: 50px;
  }
  .roadmap__release .release__phases .one p,
  .roadmap__release .release__phases .two p,
  .roadmap__release .release__phases .three p {
    left: 30px;
  }
  .schedule__stages .stages__image .s5 {
    font-size: 70px;
    line-height: 136px;
    left: 140px;
    top: -70px;
  }
  .schedule__stages .stages__image .s4 {
    font-size: 50px;
    line-height: 109px;
    left: 46%;
    top: -5px;
  }
  .schedule__stages .stages__image .s3 {
    font-size: 30px;
    line-height: 82px;
    right: 280px;
    top: 50px;
  }
  .schedule__stages .stages__image .s2 {
    font-size: 20px;
    line-height: 55px;
    right: 140px;
    top: 80px;
  }
  .schedule__stages .stages__image .s1 {
    font-size: 15px;
    line-height: 34px;
    right: 30px;
    top: 110px;
  }
  .schedule__stages .stages__image .s5__price {
    font-size: 55px;
    line-height: 75px;
    left: 70px;
    top: 350px;
  }
  .schedule__stages .stages__image .s1__price {
    font-size: 35px;
    line-height: 48px;
    opacity: 0.7;
    right: 10px;
    top: 250px;
  }
  .schedule__stages .stages__image .line__image {
    position: absolute;
    left: 30%;
    top: 290px;
    width: 500px;
  }
  .development__stage ul li {
    font-size: 18px;
    line-height: 27px;
    width: 100%;
  }
  .development__stages .hor__line {
    margin: 0;
    width: 100%;
    position: absolute;
  }
}

@media (min-width: 1280px) and (max-width: 1280px) {
  .roadmap__release .release__phases .release__phase p {
    bottom: 140px;
  }
  .roadmap__release .release__phases .four p,
  .roadmap__release .release__phases .five p,
  .roadmap__release .release__phases .six p {
    right: 70px;
  }
  .roadmap__release .release__phases .one p,
  .roadmap__release .release__phases .two p,
  .roadmap__release .release__phases .three p {
    left: 50px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 190px;
    left: 70px;
  }
  .schedule__stages .stages__image .s5 {
    font-size: 70px;
    line-height: 136px;
    left: 170px;
    top: -70px;
  }
  .schedule__stages .stages__image .s4 {
    font-size: 50px;
    line-height: 109px;
    left: 43%;
    top: 5px;
  }
  .schedule__stages .stages__image .s3 {
    font-size: 30px;
    line-height: 82px;
    right: 410px;
    top: 70px;
  }
  .schedule__stages .stages__image .s2 {
    font-size: 20px;
    line-height: 55px;
    right: 230px;
    top: 100px;
  }
  .schedule__stages .stages__image .s1 {
    font-size: 15px;
    line-height: 34px;
    right: 110px;
    top: 140px;
  }
  .schedule__stages .stages__image .s5__price {
    font-size: 55px;
    line-height: 75px;
    left: 100px;
    top: 420px;
  }
  .schedule__stages .stages__image .s1__price {
    font-size: 35px;
    line-height: 48px;
    opacity: 0.7;
    right: 50px;
    top: 300px;
  }
  .schedule__stages .stages__image .line__image {
    position: absolute;
    left: 30%;
    top: 330px;
    width: 650px;
  }
}
@media (min-width: 360px) and (max-width: 540px) {
  .roadmap__release .release__phases .release__phase p {
    bottom: 24px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 60px;
    left: 15px;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  .roadmap__release .release__phases .release__phase p {
    bottom: 14px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 50px;
    left: 15px;
  }
}
@media (min-width: 540px) and (max-width: 540px) {
  .roadmap__release .release__phases .release__phase p {
    bottom: 30px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 70px;
    left: 15px;
  }
  .roadmaps .roadmap__cards {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .roadmaps .roadmap__card {
    width: 40%;
  }
  .development__stages .six {
    margin-top: 300px !important;
  }
  .development__stages .seven {
    margin-top: 500px !important;
  }
}
@media (min-width: 280px) and (max-width: 280px) {
  .roadmap__release .release__phases .release__phase p {
    bottom: 5px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 40px;
    left: 15px;
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 912px) and (max-width: 912px) {
  .roadmap__release .release__phases .release__phase p {
    bottom: 84px;
  }
  .roadmap__release .release__phase .active__phase {
    bottom: 140px;
    left: 29px;
  }
}


@media (max-width: 1024px) {
    .development__stage, .development__stages .reverse__stage {
        flex-direction: column;
        /* margin-bottom: 800px; */
    }
    .release__phase .image {
        width: 100%;
    }
    .development__stage .light {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
    }
    
    .development__stage .light p {
        width: 100%;
        font-size: 18px !important;
        width: 90%;
        margin: 0 auto;
    }
    .development__stage .stage__details {
        width: 100%;
    }
    .development__image img {
        /* margin-top: 100px; */
    }
    .schedule__stages .stages__image img {
        width: 100%;
    }
    
    .roadmap__schedule .schedules .schedule__top, .roadmap__schedule .schedules .schedule__bottom {
        flex-direction: column;
        padding: 20px 20px 0 0;
    }
    .roadmap__schedule .schedules .schedule__top .schedule, .roadmap__schedule .schedule__bottom .schedule {
        width: 100%;
        padding: 10px;
    }
    .development__stages .one {
        margin-bottom: 500px;
    }
    .development__stages .two {
        margin-top: 100px;
        margin-bottom: 900px;
    }
    .development__stages .three {
        margin-bottom: 350px;
    }
    .development__stages .four {
        margin-top: 100px;
        margin-bottom: 500px;
    }
    .development__stages .five {
        margin-top: 300px;
        margin-bottom: -220px;
    }
    .development__stages .six {
        margin-top: 100px;
        margin-bottom: 240px;
    }
    .development__stages .seven {
        margin-top: 100px;
    }
}

@media (min-width: 360px) and (max-width: 393px) {
    .development__stages .one {
        margin-bottom: 600px;
    }
    .development__stages .two {
        margin-top: 100px;
        margin-bottom: 1100px;
    }
    .development__stages .three {
        margin-bottom: 550px;
    }
    .development__stages .four {
        margin-top: 100px;
        margin-bottom: 500px;
    }
    .development__stages .five {
        margin-top: 300px;
        margin-bottom: -220px;
    }
    .development__stages .six {
        margin-top: 100px;
        margin-bottom: 240px;
    }
    .development__stages .seven {
        margin-top: 100px;
    }
}

@media (min-width: 540px) and (max-width: 1024px) {
    .development__stage .light {
        width: 357px;
  height: 350px;
    }
    
}

@media (min-width: 712px) and (max-width: 1024px) {
    .development__stages .one {
        margin-bottom: 100px;
    }
    .development__stages .two {
        margin-top: 100px;
        margin-bottom: -280px;
    }
    .development__stages .three {
        margin-bottom: 150px;
    }
    .development__stages .four {
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .development__stages .five {
        margin-top: 300px;
        margin-bottom: -320px;
    }
    .development__stages .six {
        margin-top: 400px;
        margin-bottom: -200px;
    }
    .development__stages .seven {
        margin-top: 500px;
    }
    .roadmap__cards .straight__line {
        width: 100%;
        top: 150px !important;
    }
    .schedules img {
        width: 100%;
        display: none;
    }
}
.roadmap__cards .straight__line {
  top: 100px !important;
}

@media (min-width: 1280px) and (max-width: 1280px) {
    .roadmap__cards .straight__line {
        width: 100%;
        top: 100px;
    }
}


@media (min-width: 280px) and (max-width: 280px) {
    .development__image img {
        width: 100%;
    }
    .development__stage .stage__details .details p {
        font-size: 16px;
    }
    .development__stage .stage__details .details .sub h5 {
        font-size: 20px;
    }
    .development__stages .one {
        margin-bottom: 700px;
    }
    .development__stages .two {
        margin-top: 100px;
        margin-bottom: 1100px;
    }
    .development__stages .three {
        margin-bottom: 550px;
    }
    .development__stages .four {
        margin-top: 100px;
        margin-bottom: 500px;
    }
    .development__stages .five {
        margin-top: 300px;
        margin-bottom: -120px;
    }
    .development__stages .six {
        margin-top: 300px;
        margin-bottom: 340px;
    }
    .development__stages .six .light img {
      width: 50px;
      margin-top: -20px;
    }
    .development__stages .seven {
        margin-top: 500px;
    }
    .development__stage .light p {
        font-size: 16px !important;
        line-height: 25px !important;
    }
    .development__stage .stage__details .details .sub h5 {
        font-size: 18px;
        margin-bottom: 40px;
    }
    .development__stage .stage__details .details .sub div {
        display: none;
    }
    .development__stage .light {
        
    width: 257px;
    height: 250px;
    padding: 20px !important;
    
    }
    .development__stages .six .light p {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .development__stage .light img {
      width: 50px;
    }
}

@media (min-width: 300px) and (max-width: 414px) {
  .development__stages .one {
    margin-bottom: 700px;
}
.development__stages .two {
    margin-top: 100px;
    margin-bottom: 1100px;
}
.development__stages .three {
    margin-bottom: 550px;
}
.development__stages .four {
    margin-top: 100px;
    margin-bottom: 500px;
}
.development__stages .five {
    margin-top: 300px;
    margin-bottom: -120px;
}
.development__stages .six {
    margin-top: 300px;
    margin-bottom: 340px;
}
.development__stages .six .light img {
  width: 50px;
  margin-top: -20px;
}
.development__stages .seven {
    margin-top: 500px;
}
}